import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function LookupIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon color={"inherit"} viewBox="0 0 12 12" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.99503 0.0056438C3.95636 0.011011 3.8298 0.0269016 3.71378 0.0409641C2.46112 0.192862 1.26499 0.988378 0.596339 2.11427C-0.35016 3.70802 -0.154832 5.76712 1.07465 7.15693C1.7396 7.90857 2.57376 8.37343 3.59659 8.56232C3.91687 8.62147 4.69166 8.6214 5.01456 8.5622C5.70869 8.43496 6.32155 8.1767 6.84269 7.79193L6.99503 7.67945L7.06199 7.75581C7.14554 7.85106 7.24061 8.04744 7.24079 8.12514C7.24089 8.15736 7.21989 8.21825 7.19415 8.26046C7.13033 8.36518 7.13476 8.50025 7.20672 8.64224C7.27086 8.76882 7.27752 8.77593 9.11352 10.6744C10.3826 11.9867 10.3898 11.993 10.6205 11.9918C10.6761 11.9915 10.7668 11.9708 10.822 11.9457C10.9349 11.8945 11.7801 11.1011 11.8757 10.9567C11.9584 10.8318 11.9747 10.6087 11.9115 10.4659C11.8768 10.3876 11.4928 9.97698 10.3884 8.8374C9.57629 7.99937 8.87075 7.27979 8.8206 7.23838C8.77044 7.19697 8.67322 7.14533 8.60453 7.12363C8.48247 7.08505 8.4775 7.08533 8.38187 7.13598C8.32811 7.16446 8.25189 7.18773 8.21251 7.18771C8.1156 7.18761 7.92475 7.0892 7.82807 6.98945L7.74882 6.9077L7.88727 6.70197C8.22571 6.19914 8.46679 5.58097 8.56623 4.96108C8.64688 4.45832 8.63462 3.98467 8.52679 3.43764C8.18429 1.69999 6.74233 0.322425 4.97941 0.0487219C4.76669 0.015675 4.12525 -0.0123796 3.99503 0.0056438ZM4.90445 1.40113C5.7588 1.57108 6.50697 2.12664 6.92111 2.89858C7.67896 4.31123 7.18476 6.06489 5.79972 6.8777C4.68341 7.5328 3.2245 7.36422 2.27425 6.47029C1.13999 5.40327 1.02318 3.6588 2.00402 2.43506C2.4112 1.92705 2.96214 1.58395 3.64851 1.41098C4.00042 1.32231 4.48891 1.31847 4.90445 1.40113Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
