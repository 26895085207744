import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function ComingSoonIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon color={"inherit"} viewBox="0 0 10 10" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.46504 0.0196444C7.26547 0.195562 5.63383 1.10283 4.12911 2.43062C3.98608 2.55683 3.87743 2.63699 3.82999 2.6513C3.76378 2.67128 3.71126 2.66337 3.35729 2.58005C2.91007 2.4748 2.83354 2.47292 2.62905 2.56228C2.52993 2.60558 2.38194 2.74669 1.27366 3.85468C0.589384 4.53878 0.0205176 5.12267 0.00948244 5.1522C-0.0150488 5.21796 0.00954103 5.29255 0.0656543 5.32259C0.0876464 5.33435 0.525322 5.41015 1.03827 5.49103C1.71608 5.5979 1.99472 5.63458 2.05804 5.62527C2.18567 5.60648 2.33249 5.54675 2.44139 5.46933C2.49407 5.43187 2.5546 5.40122 2.57587 5.40122C2.60087 5.40122 2.96169 5.74814 3.59643 6.38241C4.3563 7.14171 4.57833 7.37437 4.57833 7.41124C4.57833 7.43747 4.54764 7.50351 4.51014 7.558C4.4188 7.69077 4.36348 7.85542 4.36348 7.99456C4.36348 8.1269 4.62596 9.81817 4.65905 9.89903C4.68413 9.9603 4.73004 9.99106 4.79641 9.99106C4.82973 9.99106 5.16715 9.66544 6.10692 8.72642C7.49621 7.33821 7.46094 7.37935 7.4825 7.1221C7.49186 7.01064 7.48116 6.93925 7.41289 6.65702C7.36852 6.47366 7.33223 6.29214 7.33223 6.25366C7.33223 6.192 7.35959 6.15286 7.56262 5.92429C8.70967 4.63284 9.55701 3.18253 9.86375 1.98568C10.0203 1.37503 10.0161 0.919839 9.8507 0.562339C9.72891 0.299058 9.51244 0.120914 9.21699 0.0407967C9.05399 -0.0034025 8.69166 -0.0135978 8.46504 0.0196444ZM7.76719 1.4846C7.94762 1.52285 8.12004 1.61873 8.25996 1.75865C8.83301 2.33171 8.55647 3.31312 7.76967 3.49863C7.5151 3.55865 7.1933 3.50646 6.98225 3.37095C6.5292 3.08007 6.37159 2.51515 6.60871 2.03208C6.82246 1.59662 7.27856 1.38107 7.76719 1.4846ZM2.332 6.31196C2.22432 6.40923 2.01134 6.68023 1.85809 6.91489C1.36788 7.66562 0.904111 8.82112 0.996435 9.06173C1.01671 9.11458 1.07632 9.09731 1.4003 8.94472C1.57302 8.86337 1.94784 8.70448 2.23325 8.59163C2.98938 8.29267 3.19139 8.17651 3.58194 7.81612C3.77469 7.63827 3.78542 7.62314 3.76825 7.55314C3.75401 7.49509 2.5936 6.3163 2.50411 6.26896C2.43011 6.22982 2.42016 6.23232 2.332 6.31196Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
