import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { useFilledIconColor } from "../utils/useFilledIconColor";

export function LookupFilledPrimaryIcon({
  color,
  ...props
}: SvgIconProps): JSX.Element {
  const [circleFill, pathFill] = useFilledIconColor(color);
  return (
    <SvgIcon {...props} viewBox="0 0 22 22">
      <circle cx="11" cy="11" r="11" fill={circleFill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.6785 5.03425C7.39956 5.23391 6.30897 5.95283 5.632 7.04251C5.33144 7.52631 5.11204 8.13674 5.02783 8.72345C4.99145 8.97686 4.99054 9.6391 5.02626 9.8836C5.23752 11.3303 6.09527 12.5346 7.37137 13.1762C8.79606 13.8925 10.5621 13.7537 11.8369 12.8253L12.0009 12.7058L12.0679 12.783C12.2188 12.957 12.2617 13.1537 12.1767 13.282C12.1511 13.3207 12.1301 13.38 12.13 13.4139C12.1298 13.504 12.1907 13.6614 12.2645 13.7615C12.3 13.8097 12.9935 14.5365 13.8056 15.3766C14.8324 16.4387 15.3112 16.9182 15.3775 16.9505C15.4919 17.0062 15.7259 17.0129 15.8433 16.9638C15.9693 16.9112 16.85 16.0565 16.9149 15.9239C16.9858 15.779 16.9865 15.5723 16.9164 15.4362C16.848 15.3033 13.8975 12.2605 13.7574 12.1784C13.6074 12.0905 13.4727 12.0796 13.3387 12.1444C13.2363 12.194 13.2274 12.1946 13.1203 12.1591C12.9978 12.1184 12.789 11.9648 12.7872 11.9141C12.7866 11.8965 12.822 11.8332 12.8658 11.7735C13.0757 11.4875 13.3076 11.0114 13.4382 10.5984C13.5897 10.119 13.6414 9.71282 13.6228 9.14816C13.6015 8.50354 13.4806 8.00472 13.209 7.44144C12.5762 6.12868 11.381 5.25048 9.93107 5.0327C9.63641 4.98843 8.96666 4.98927 8.6785 5.03425ZM9.78784 6.39395C10.4102 6.50008 10.9588 6.7828 11.4014 7.22535C11.939 7.76293 12.2351 8.44211 12.2645 9.20488C12.3093 10.3688 11.6686 11.4397 10.6114 11.9677C9.96032 12.2929 9.13377 12.3547 8.42272 12.1313C7.97052 11.9892 7.58896 11.7537 7.23102 11.3957C6.65027 10.815 6.36423 10.1265 6.36423 9.30939C6.36423 8.79601 6.45974 8.40376 6.69416 7.95444C6.86212 7.63248 6.98912 7.46127 7.25378 7.20001C7.67945 6.77985 8.24214 6.49243 8.83354 6.39308C9.09911 6.34848 9.52351 6.34886 9.78784 6.39395Z"
        fill={pathFill}
      />
    </SvgIcon>
  );
}
