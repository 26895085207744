import React, { forwardRef } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const AlertPrimaryIcon = forwardRef<SVGSVGElement, SvgIconProps>(
  function AlertPrimaryIcon(props, ref): JSX.Element {
    return (
      <SvgIcon ref={ref} {...props} viewBox="0 0 22 22" fill="none">
        <circle cx="11" cy="11" r="11" fill="#FF0000" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.9136 5.03877C10.7121 5.09669 10.5653 5.18719 10.3941 5.35904C10.2144 5.53949 10.1126 5.72209 10.064 5.95132C10.037 6.07877 10.0231 6.2715 10.0409 6.2715C10.0449 6.2715 10.1025 6.25282 10.1688 6.23C10.5597 6.09552 11.1347 6.02463 11.5554 6.05907C11.8984 6.08716 12.3079 6.17131 12.5412 6.26166C12.573 6.27403 12.5782 6.26304 12.5779 6.18365C12.5767 5.88646 12.447 5.58928 12.2178 5.35876C12.0434 5.18335 11.8814 5.08625 11.674 5.03277C11.4947 4.98657 11.084 4.98981 10.9136 5.03877ZM11.0727 6.67801C9.94833 6.74977 8.92047 7.47955 8.47304 8.52372C8.2835 8.96604 8.23587 9.24407 8.21045 10.0564C8.18903 10.7411 8.15421 11.13 8.0709 11.6152C7.98936 12.0901 7.85598 12.5514 7.70025 12.897C7.56188 13.204 7.2919 13.5668 7.14535 13.6426C6.94723 13.745 6.95287 14.0793 7.1548 14.2024C7.22313 14.2441 7.27006 14.2446 11.3059 14.2446C15.3418 14.2446 15.3888 14.2441 15.4571 14.2024C15.659 14.0793 15.6647 13.745 15.4665 13.6426C15.32 13.5668 15.05 13.204 14.9116 12.897C14.6043 12.2148 14.4029 11.1054 14.4018 10.0885C14.4011 9.42278 14.3291 8.98074 14.1525 8.55814C13.9972 8.18632 13.811 7.90699 13.5133 7.59921C12.8708 6.93483 12.0287 6.617 11.0727 6.67801ZM9.80036 14.8867C9.80036 14.9417 9.96364 15.1881 10.0929 15.328C10.4255 15.6879 10.8123 15.8563 11.3065 15.8563C11.8012 15.8563 12.1855 15.6889 12.519 15.328C12.6482 15.1881 12.8115 14.9417 12.8115 14.8867C12.8115 14.865 12.5106 14.8597 11.3059 14.8597C10.1013 14.8597 9.80036 14.865 9.80036 14.8867Z"
          fill="white"
        />
      </SvgIcon>
    );
  }
);

export { AlertPrimaryIcon };
