import React, { forwardRef } from "react";
import { Avatar, AvatarProps, SvgIcon } from "@mui/material";

type VaiThuHayAvatarProps = Omit<AvatarProps, "src" | "children">;
const VaithuhayAvatar = forwardRef<HTMLDivElement, VaiThuHayAvatarProps>(
  function VaiThuHayAvatar(props, ref): JSX.Element {
    return (
      <Avatar ref={ref} {...props}>
        <SvgIcon
          sx={{
            width: `${(12.0 * 100) / 32}%`,
            height: `${(15.0 * 100) / 32}%`,
            marginLeft: `-${(2.0 * 100) / 32}%`,
          }}
          viewBox="0 0 12 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.3934 0.0258363C10.0212 0.132592 9.81921 0.364514 9.73039 0.786953C9.70029 0.9301 9.69748 1.09913 9.70967 2.03676C9.71743 2.63311 9.73339 3.52764 9.74514 4.0246C9.75688 4.52156 9.78158 5.6753 9.80004 6.58846C9.8185 7.50162 9.84315 8.63503 9.85483 9.10714C9.89291 10.6462 9.90696 11.8898 9.88642 11.9024C9.85093 11.9243 4.46776 6.80193 2.49258 4.86679C1.87352 4.26029 1.75017 4.15882 1.53153 4.07633C1.34 4.00407 0.971431 4.00411 0.76527 4.07644C0.471378 4.17952 0.266393 4.3631 0.125975 4.64898C-0.0294053 4.96539 -0.0410644 5.27158 0.091086 5.56578C0.216919 5.84587 0.114648 5.74878 2.40793 7.76497C4.37354 9.49308 6.23034 11.1222 7.61681 12.3351C8.4397 13.0551 9.31102 13.8227 9.55307 14.0411C10.0751 14.5121 10.2768 14.6702 10.4914 14.7769C11.1075 15.083 11.6727 14.8721 11.8052 14.2866C11.8194 14.2239 11.8274 11.8746 11.8277 7.62552C11.8282 1.13329 11.8278 1.05942 11.7839 0.884628C11.7595 0.787427 11.7128 0.661425 11.6802 0.604637C11.5473 0.37355 11.2797 0.152809 11.0183 0.0585904C10.8455 -0.00361995 10.5501 -0.0191386 10.3934 0.0258363Z"
            fill="#EBD139"
          />
        </SvgIcon>
      </Avatar>
    );
  }
);

export { VaithuhayAvatar };
